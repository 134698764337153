import React from "react"
import styled from "styled-components"

import Layout from "./../components/layout"
import NavGrid from "./../components/navGrid"
import { Device } from "./../components/theme"

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-gap: 2rem;

    @media ${Device.mobile} {
      grid-template-columns: 1fr;
    }
`

const Content = styled.div`
    display: grid;
    grid-column-start: 3;
    grid-column-end: 7;
    line-height: 1.5;

    @media ${Device.mobile} {
      margin: 1rem;
      grid-column: 1;
    }
`

const NotFoundPage = () => {

    return (
        <Layout>
            <NavGrid />
            <Grid>
                <Content>
                    <h1>404 - Not Found</h1>
                    <p>Sorry! Diese Seite existiert nicht.</p>
                </Content>
            </Grid>
        </Layout>
    )
}

export default NotFoundPage